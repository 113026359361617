/* purgecss start ignore */

.ProductSearchCard_container__e97i7 {
  width: 100%;
}

.ProductSearchCard_content__uKoH_ {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

.ProductSearchCard_brand__Db_r4 {
  font-family: Inter, sans-serif;
  font-size:  0.75rem;
  line-height: 1.125rem;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--primaryA);
  line-height: 1.375rem;
}

.ProductSearchCard_title__Er_yY {
  font-family: Inter, sans-serif;
  font-size:  0.75rem;
  line-height: 1.125rem;
  min-width: 100%;
  overflow: hidden;
  font-size: .875rem;
  line-height: 1.125rem;
  line-height: 1.375rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ProductSearchCard_title__Er_yY mark {
  background-color: transparent;
  font-weight: 500;
}

.ProductSearchCard_prices__iY3LG {
  display: flex;
  flex-direction: row;
  font-family: inter, sans-serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.ProductSearchCard_labelFrom__NgrTu {
  font-weight: 400;
}

.ProductSearchCard_initialPrice__r_XeM {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-weight: 400;
  text-decoration: line-through;
}

.ProductSearchCard_salePrice__UlZYC {
  color: var(--primaryA);
}

.ProductSearchCard_discountAmount__WLIdg {
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  background-color: var(--primaryA);
  text-align: center;
  text-transform: none;
  color: var(--primaryB);
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 0 0.3125rem;
  border-radius: 0.1875rem;
}

[dir='rtl'] .ProductSearchCard_discountAmount__WLIdg {
  margin-right: 0.25rem;
  margin-left: 0px;
}

.ProductSearchCard_attrTag__F3Zta {
  display: flex;
}

.ProductSearchCard_siblings__2JIoZ {
  font-size: .875rem;
  line-height: 1.125rem;
}

.ProductSearchCard_status__wV9ZK {
  font-family: Inter, sans-serif;
  font-size:  0.75rem;
  line-height: 1.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ProductSearchCard_status__wV9ZK:not(:first-child):before {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: var(--grey600);
  content: '|';
}

.ProductSearchCard_offer__KGmBo {
  color: var(--negative);
}

.ProductSearchCard_greenable__BFFia {
  color: var(--greenable);
}

.ProductSearchCard_charitable___jf_f {
  color: var(--charitable);
}

.ProductSearchCard_exclusive__7qE23 {
  color: var(--grey600);
}

.ProductSearchCard_new__r8d5x {
  color: var(--grey600);
}

/* purgecss end ignore */

/* purgecss start ignore */

.HitsSearchBar_container__MnYIf {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

@media (min-width: 1201px) {

.HitsSearchBar_container__MnYIf {
    grid-template-columns: repeat(5, minmax(0, 1fr));

    padding-bottom: 0px;

    padding-left: 1.5rem
}
  }

.HitsSearchBar_title__GEN_h {
  display: none
}

@media (min-width: 1201px) {

.HitsSearchBar_title__GEN_h {
    font-family: Inter, sans-serif;

    font-size:  0.75rem;

    line-height: 1.125rem;

    display: block;

    padding-top: 0.75rem;

    padding-bottom: 0.75rem;

    padding-left: 1.5rem;

    font-size: .875rem;

    line-height: 1.125rem;

    text-transform: uppercase;

    color: var(--grey500)
}
  }

.HitsSearchBar_hit__x05Da {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

@media (min-width: 1201px) {

.HitsSearchBar_hit__x05Da {
    width: 12.5rem;

    border-bottom-width: 1px;

    border-color: var(--grey300);

    padding-top: 0px;

    padding-bottom: 1rem;

    border-bottom-width: 0px
}
  }

/* purgecss end ignore */

/* purgecss start ignore */

.Link_link__3shyH {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Link_link__3shyH > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.Link_link__3shyH {
  text-align: left;
  line-height: 1.625rem;
}

.Link_uppercase__ppWN5:first-letter {
  text-transform: uppercase;
}

.Link_label__OveEb {
  display: inline-block
}

@media (min-width: 1201px) {

.Link_label__OveEb {
    display: flex;

    align-items: center;

    justify-content: center
}
  }

.Link_arrow__AMVrf {
  display: none
}

@media (min-width: 1201px) {

.Link_arrow__AMVrf {
    margin-right: 0.25rem;

    display: flex
}
  }

@media (min-width: 1201px) {

[dir='rtl'] .Link_arrow__AMVrf {
    margin-right: 0px;

    margin-left: 0.25rem
}
  }

.Link_brandTag__2wUSC {
  border-radius: 0.25rem;
  background-color: var(--grey100);
  font-size: .6875rem;
  line-height: 1.125rem;
  color: var(--primaryA);
  padding: 0 0.3125rem;
  margin-left: 6px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.HitsSuggestions_suggestion__RxLdQ {
  background-color: var(--primaryB)
}

@media (min-width: 1201px) {

.HitsSuggestions_suggestion__RxLdQ {
    padding-left: 1.875rem;

    margin-top: -1rem;

    padding-top: 0px;

    padding-bottom: 0px
}
  }

.HitsSuggestions_noMarginTop___9TQa {
  margin-top: 0px;
}

.HitsSuggestions_container__ZYBS1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  padding-top: 0.25rem;
  padding-bottom: 1rem
}

@media (min-width: 1201px) {

.HitsSuggestions_container__ZYBS1 {
    padding-top: 1.3125rem;

    margin-right: 2.5rem;

    padding-bottom: 0px
}
  }

@media (min-width: 1201px) {

[dir='rtl'] .HitsSuggestions_container__ZYBS1 {
    margin-right: 0px;

    margin-left: 2.5rem
}
  }

.HitsSuggestions_noPaddingTop__Jcdko {
  padding-top: 0px;
}

.HitsSuggestions_noPaddingBottom__kh3_I {
  padding-bottom: 0px;
}

.HitsSuggestions_container__ZYBS1::-webkit-scrollbar {
  display: none;
}

.HitsSuggestions_hit__FDcsg {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--grey500);
  text-decoration: none;
}

@media (min-width: 992px) {
  .HitsSuggestions_hit__FDcsg {
    font-size: .875rem;
    line-height: 1.125rem;
  }
}

@media (min-width: 1201px) {
  .HitsSuggestions_hit__FDcsg {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
}

.HitsSuggestions_hit__FDcsg mark {
  background-color: transparent;
  font-weight: 500;
}

.HitsSuggestions_hit__FDcsg .HitsSuggestions_link___zR0_ mark {
  background-color: transparent;
}

.HitsSuggestions_link___zR0_:first-letter {
  text-transform: uppercase;
}

.HitsSuggestions_hit__FDcsg span:first-letter {
  text-transform: uppercase;
}

.HitsSuggestions_title__FXBVZ {
  font-family: Inter, sans-serif;
  font-size:  0.75rem;
  line-height: 1.125rem;
  font-size: .875rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  color: var(--grey500);
}

@media (min-width: 1201px) {
  .HitsSuggestions_title__FXBVZ {
    padding-bottom: 0.25rem;
    padding-left: 1.5rem;
  }
}

.HitsSuggestions_link___zR0_ {
  cursor: pointer
}

.HitsSuggestions_link___zR0_:hover {
  text-decoration: underline;
}

.HitsSuggestions_link___zR0_ {
  line-height: 1.375rem;
}

@media (min-width: 1201px) {

.HitsSuggestions_link___zR0_ {
    text-decoration: none;
}
  }

.HitsSuggestions_text_uppercase__60tWA {
  text-transform: uppercase;
}

.HitsSuggestions_inline___fhz_ {
  display: flex;
  flex-direction: row;
}

.HitsSuggestions_loupe__k4erL {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.SearchBarAlgolia_container__wwdEz {
  position: relative;
  height: auto;
  max-height: 100%;
}

.SearchBarAlgolia_container__wwdEz.SearchBarAlgolia_deploy__f7zaK {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--primaryB);
}

@media (min-width: 1201px) {
  .SearchBarAlgolia_container__wwdEz.SearchBarAlgolia_deploy__f7zaK {
    background-color: transparent;
  }
}

.SearchBarAlgolia_content__Dx_QO {
  position: relative;
  height: auto;
  background-color: var(--primaryB);
}

.SearchBarAlgolia_searchBox__OMi_S {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 40;
  width: 100%;
  border-bottom-width: 0px;
  background-color: var(--primaryB)
}

@media (min-width: 1201px) {

.SearchBarAlgolia_searchBox__OMi_S {
    position: relative;

    height: auto;

    width: auto;

    border-bottom-width: 0px
}
  }

.SearchBarAlgolia_resultsContainer__K2Zbu {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.5rem
}

@media (min-width: 1201px) {

.SearchBarAlgolia_resultsContainer__K2Zbu {
    max-width: 90rem;

    position: relative;

    margin: 0px;

    margin-left: auto;

    margin-right: auto;

    display: flex;

    height: auto;

    background-color: var(--primaryB);

    padding: 0px
}
  }

.SearchBarAlgolia_deploy__f7zaK .SearchBarAlgolia_resultsContainer__K2Zbu {
  height: 100%;
}

@media (min-width: 1201px) {
  .SearchBarAlgolia_deploy__f7zaK .SearchBarAlgolia_resultsContainer__K2Zbu {
    height: auto;
  }
}

.SearchBarAlgolia_deploy__f7zaK .SearchBarAlgolia_resultsContainer__K2Zbu,
.SearchBarAlgolia_noResult__83b0z {
  padding-top: 3.5rem;
}

@media (min-width: 1201px) {
  .SearchBarAlgolia_deploy__f7zaK .SearchBarAlgolia_resultsContainer__K2Zbu, .SearchBarAlgolia_noResult__83b0z {
    padding-top: 0px;
  }
}

@media (min-width: 1201px) {
  .SearchBarAlgolia_deploy__f7zaK.SearchBarAlgolia_withSuggestion__RXXA6 .SearchBarAlgolia_resultsContainer__K2Zbu, .SearchBarAlgolia_withSuggestion__RXXA6 .SearchBarAlgolia_noResult__83b0z {
    padding-top: 0px;
  }
}

.SearchBarAlgolia_searchBoxForm__2n3mg {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 1201px) {

.SearchBarAlgolia_searchBoxForm__2n3mg {
    max-width: 90rem;

    margin: 0px;

    margin-left: auto;

    margin-right: auto;

    background-color: var(--primaryB);

    padding: 0px
}
  }

.SearchBarAlgolia_box__BY2oV {
  margin: 0 !important;
}

.SearchBarAlgolia_box_left__9BQPA {
  margin: 0 !important
}

@media (min-width: 1201px) {

.SearchBarAlgolia_box_left__9BQPA {
    margin: 0 0 1rem 0 !important
}
  }

.SearchBarAlgolia_leftPanel__8Bo_5 {
  width: 100%
}

@media (min-width: 1201px) {
  .SearchBarAlgolia_leftPanel__8Bo_5 {
    height: 100%;
  }
}

@media (min-width: 1201px) {

.SearchBarAlgolia_leftPanel__8Bo_5 {
    margin-top: 0.75rem;

    border-right-width: 1px;

    border-color: var(--grey300);
}
  }

.SearchBarAlgolia_rightPanel__6DGy5 {
  width: 100%;
  margin-top: 0 !important
}

@media (min-width: 1201px) {

.SearchBarAlgolia_rightPanel__6DGy5 {
    padding-bottom: 0px
}
  }

.SearchBarAlgolia_categories__vjKZ_ {
  padding-top: 0.5rem
}

@media (min-width: 1201px) {

.SearchBarAlgolia_categories__vjKZ_ {
    padding-top: 0px;

    padding-bottom: 1.25rem
}
  }

.SearchBarAlgolia_full_width__BS_vA {
  margin: 0px !important;
  width: 100% !important;
}

.SearchBarAlgolia_results__nzn3_ {
  width: 100%;
}

.SearchBarAlgolia_seeMore__lqPmV {
  margin-top: 0.5rem;
}

@media (min-width: 1201px) {
  .SearchBarAlgolia_seeMore__lqPmV {
    margin-top: 0px;
  }
}

.SearchBarAlgolia_recentSuggestion__mskgx {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 1rem;
  padding-bottom: 7rem;
}

@media (min-width: 992px) {
  .SearchBarAlgolia_recentSuggestion__mskgx {
    padding-top: 0px;
  }
}

.SearchBarAlgolia_recentSuggestion__mskgx {
  padding-left: 1.875rem;
}

.SearchBarAlgolia_hit__NAosU {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--grey500);
  text-decoration: none;
}

@media (min-width: 992px) {
  .SearchBarAlgolia_hit__NAosU {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: .875rem;
    line-height: 1.125rem;
  }
}

.SearchBarAlgolia_hit__NAosU {
  line-height: 1.375rem;
}

.SearchBarAlgolia_link__mwgYh {
  cursor: pointer
}

.SearchBarAlgolia_link__mwgYh:hover {
  text-decoration: underline;
}

.SearchBarAlgolia_link__mwgYh {
  line-height: 1.375rem;
}

@media (min-width: 1201px) {

.SearchBarAlgolia_link__mwgYh {
    text-decoration: none;
}
  }

.SearchBarAlgolia_link__mwgYh:first-letter {
  text-transform: uppercase;
}

.SearchBarAlgolia_recentSearchTitle__2rTjr {
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 500;
}

@media (min-width: 992px) {
  .SearchBarAlgolia_recentSearchTitle__2rTjr {
    font-size: .875rem;
    line-height: 1.125rem;
  }
}

.SearchBarAlgolia_deleteAllText__Ck18L {
  margin-top: 0.375rem;
  display: none;
  cursor: pointer;
  font-weight: 500;
}

@media (min-width: 992px) {
  .SearchBarAlgolia_deleteAllText__Ck18L {
    display: block;
  }
}

.SearchBarAlgolia_deleteAllText__Ck18L {
  font-size: 0.75rem;
}

.SearchBarAlgolia_deleteCross__X7lXy {
  margin-left: 0.625rem;
  display: none;
  height: 0.75rem;
  width: 0.75rem;
  cursor: pointer;
}

@media (min-width: 992px) {
  .SearchBarAlgolia_deleteCross__X7lXy {
    display: block;
  }
}

[dir='rtl'] .SearchBarAlgolia_deleteCross__X7lXy {
  margin-right: 0.625rem;
  margin-left: 0px;
}

.SearchBarAlgolia_deleteAllCross__TV2bu {
  margin-left: 1rem;
  font-weight: 400
}

@media (min-width: 992px) {

.SearchBarAlgolia_deleteAllCross__TV2bu {
    display: none
}
  }

[dir='rtl'] .SearchBarAlgolia_deleteAllCross__TV2bu {
  margin-right: 1rem;
  margin-left: 0px;
}

.SearchBarAlgolia_recentSearchTitleBlock__HUIR0 {
  display: flex;
  align-items: center;
  padding-bottom: 0.75rem
}

@media (min-width: 992px) {
  .SearchBarAlgolia_recentSearchTitleBlock__HUIR0 {
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) {

.SearchBarAlgolia_recentSearchTitleBlock__HUIR0 {
    padding-top: 1.25rem;

    padding-bottom: 0.375rem;
}
  }

.SearchBarAlgolia_brandTag___hyQB {
  border-radius: 0.25rem;
  background-color: var(--grey100);
  font-size: .6875rem;
  line-height: 1.125rem;
  color: var(--primaryA);
  padding: 0 0.3125rem;
  margin-left: 6px;
}

.SearchBarAlgolia_brandLink__m99F8 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchBarAlgolia_brandLink__m99F8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.SearchBarAlgolia_brandLink__m99F8 {
  text-align: left;
}

/* purgecss end ignore */

/* purgecss start ignore */

.SearchModal_container__7S7GK {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 50;
  display: none;
  width: 100vw;
  overflow: hidden;
}

.SearchModal_container__7S7GK.SearchModal_open__TlOur {
  display: block;
  overflow-y: auto;
}

.SearchModal_layout__xAM2R {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

@media (min-width: 992px) {

.SearchModal_layout__xAM2R {
    background-color: rgba(0, 0, 0, 0.4)
}
  }

.SearchModal_layout__xAM2R.SearchModal_disableAction__Ozyhx {
  touch-action: none;
}

@media (min-width: 1201px) {

.SearchModal_topBanner__QaYhd {
    height: 0.625rem;

    background-color: var(--primaryA)
}
  }

.SearchModal_content__21_Wf {
  height: auto;
  width: 100%;
}

.SearchModal_animation__NBMty {
  animation: SearchModal_fadeIn__XVObT 0.3s ease-in-out forwards
}

@media (min-width: 1201px) {

.SearchModal_animation__NBMty {
    animation: SearchModal_slideOutTop__gh7MF 0.3s ease-in-out forwards
}
  }

@keyframes SearchModal_fadeIn__XVObT {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes SearchModal_slideOutTop__gh7MF {
  0% {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

/* purgecss end ignore */

