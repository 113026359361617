.container {
  @apply hidden fixed top-0 left-0 w-screen overflow-hidden z-50;
}
.container.open {
  @apply block overflow-y-auto;
}

.layout {
  @apply w-full h-full fixed inset-0 bg-white;
  @screen lg {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.layout.disableAction {
  touch-action: none;
}

.topBanner {
  @screen xl {
    @apply bg-primaryA;
    height: 0.625rem;
  }
}
.content {
  @apply w-full h-auto;
}

.animation {
  animation: fadeIn 0.3s ease-in-out forwards;

  @screen xl {
    animation: slideOutTop 0.3s ease-in-out forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideOutTop {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
