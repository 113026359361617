.container {
  @apply grid grid-cols-2;

  @screen xl {
    @apply grid-cols-5 pb-0 pl-6;
  }
}

.title {
  @apply hidden;

  @screen xl {
    @apply block py-3 pl-6 typo-inter-text text-sm text-grey500 uppercase;
  }
}

.hit {
  @apply w-full py-2;

  @screen xl {
    @apply pb-4 pt-0 border-b border-grey300 xl:border-b-0;
    width: 12.5rem;
  }
}
