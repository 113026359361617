.link {
  @apply flex flex-row items-center space-x-1 text-left;
  line-height: 1.625rem;
}

.uppercase:first-letter {
  text-transform: uppercase;
}

.label {
  @apply inline-block;

  @screen xl {
    @apply flex items-center justify-center;
  }
}

.arrow {
  @apply hidden;
  @screen xl {
    @apply flex mr-1;
  }
}
[dir='rtl'] .arrow {
  @screen xl {
    @apply ml-1 mr-0;
  }
}
.brandTag {
  @apply text-xs text-primaryA bg-grey100 rounded;
  padding: 0 0.3125rem;
  margin-left: 6px;
}
