.container {
  @apply w-full;
}

.content {
  @apply flex w-full;
  justify-content: flex-start;
  flex-direction: column;
}

.brand {
  @apply text-sm font-medium uppercase typo-inter-text text-primaryA;
  line-height: 1.375rem;
}

.title {
  @apply min-w-full overflow-hidden text-sm typo-inter-text;
  line-height: 1.375rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title mark {
  @apply bg-transparent font-medium;
}

.prices {
  @apply flex flex-row text-sm font-medium font-inter;
  line-height: 1.375rem;
}

.labelFrom {
  @apply font-normal;
}

.initialPrice {
  @apply mx-1 font-normal line-through;
}

.salePrice {
  @apply text-primaryA;
}

.discountAmount {
  @apply flex items-center ml-1 text-center text-primaryB normal-case bg-primaryA;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 0 0.3125rem;
  border-radius: 0.1875rem;
}

[dir='rtl'] .discountAmount {
  @apply mr-1 ml-0;
}

.attrTag {
  @apply flex;
}

.siblings {
  @apply text-sm;
}

.status {
  @apply typo-inter-text;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.status:not(:first-child):before {
  @apply mx-2 text-grey600;
  content: '|';
}

.offer {
  @apply text-negative;
}

.greenable {
  @apply text-greenable;
}

.charitable {
  @apply text-charitable;
}

.exclusive {
  @apply text-grey600;
}

.new {
  @apply text-grey600;
}
