.container {
  @apply relative h-auto max-h-full;
}
.container.deploy {
  @apply flex flex-col justify-between h-full bg-primaryB xl:bg-transparent;
}

.content {
  @apply h-auto relative bg-primaryB;
}

.searchBox {
  @apply w-full fixed top-0 left-0 z-40 bg-primaryB border-b-0;
  @screen xl {
    @apply w-auto h-auto relative border-b-0;
  }
}

.resultsContainer {
  @apply block w-full h-full relative px-2.5 pb-2;

  @screen xl {
    @apply flex relative h-auto p-0 m-0 mx-auto bg-primaryB;
    max-width: 90rem;
  }
}
.deploy .resultsContainer {
  @apply h-full xl:h-auto;
}
.deploy .resultsContainer,
.noResult {
  padding-top: 3.5rem;
  @apply xl:pt-0;
}
.deploy.withSuggestion .resultsContainer,
.withSuggestion .noResult {
  @apply xl:pt-0;
}

.searchBoxForm {
  @apply my-2 mx-4;

  @screen xl {
    @apply p-0 m-0 mx-auto bg-primaryB;
    max-width: 90rem;
  }
}

.box {
  margin: 0 !important;
}

.box_left {
  margin: 0 !important;
  @screen xl {
    margin: 0 0 1rem 0 !important;
  }
}

.leftPanel {
  @apply w-full xl:h-full;
  @screen xl {
    @apply mt-3 border-r border-grey300;
  }
}

.rightPanel {
  @apply w-full;
  margin-top: 0 !important;
  @screen xl {
    @apply pb-0;
  }
}

.categories {
  @apply pt-2;
  @screen xl {
    @apply pt-0 pb-5;
  }
}

.full_width {
  @apply w-full m-0 !important;
}

.results {
  @apply w-full;
}

.seeMore {
  @apply mt-2 xl:mt-0;
}

.recentSuggestion {
  @apply bg-white  pt-4 lg:pt-0 pb-28 w-full;
  padding-left: 1.875rem;
}

.hit {
  @apply no-underline text-grey500 flex items-center whitespace-nowrap text-base lg:text-sm py-3 lg:py-1.5;
  line-height: 1.375rem;
}

.link {
  @apply hover:underline cursor-pointer;
  line-height: 1.375rem;

  @screen xl {
    @apply no-underline;
  }
}

.link:first-letter {
  text-transform: uppercase;
}

.recentSearchTitle {
  @apply text-base lg:text-sm  font-medium;
}

.deleteAllText {
  @apply hidden lg:block font-medium mt-1.5   cursor-pointer;
  font-size: 0.75rem;
}

.deleteCross {
  @apply hidden lg:block h-3 w-3 ml-2.5 cursor-pointer;
}

[dir='rtl'] .deleteCross {
  @apply mr-2.5 ml-0;
}

.deleteAllCross {
  @apply font-normal ml-4;
  @screen lg {
    @apply hidden;
  }
}

[dir='rtl'] .deleteAllCross {
  @apply mr-4 ml-0;
}

.recentSearchTitleBlock {
  @apply flex items-center  pb-3 lg:pb-0;
  @screen lg {
    @apply pb-1.5 pt-5;
  }
}

.brandTag {
  @apply text-xs text-primary bg-grey100 rounded;
  padding: 0 0.3125rem;
  margin-left: 6px;
}

.brandLink {
  @apply flex flex-row items-center space-x-1 text-left;
}
