.suggestion {
  @apply bg-primaryB;
  @screen xl {
    @apply py-0;
    padding-left: 1.875rem;
    margin-top: -1rem;
  }
}
.noMarginTop {
  @apply mt-0;
}

.container {
  @apply flex flex-col items-start overflow-y-scroll pb-4 pt-1;

  @screen xl {
    @apply pb-0 mr-10;
    padding-top: 1.3125rem;
  }
}
[dir='rtl'] .container {
  @screen xl {
    @apply ml-10 mr-0;
  }
}

.noPaddingTop {
  @apply pt-0;
}

.noPaddingBottom {
  @apply pb-0;
}

.container::-webkit-scrollbar {
  display: none;
}

.hit {
  @apply no-underline text-grey500 flex items-center whitespace-nowrap text-base my-3 xl:my-1.5 lg:text-sm;
}

.hit mark {
  @apply bg-transparent font-medium;
}

.hit .link mark {
  @apply bg-transparent;
}

.link:first-letter {
  text-transform: uppercase;
}

.hit span:first-letter {
  text-transform: uppercase;
}

.title {
  @apply text-sm uppercase xl:pb-1 xl:pl-6 typo-inter-text text-grey500;
}

.link {
  @apply hover:underline cursor-pointer;
  line-height: 1.375rem;

  @screen xl {
    @apply no-underline;
  }
}

.text_uppercase {
  @apply uppercase;
}

.inline {
  @apply flex flex-row;
}

.loupe {
  @apply pl-2.5;
  padding-right: 0.625rem;
}
